import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { filePreviewService } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';
import FileManagerDocumentPreviewer from './FileManagerDocumentPreviewer';
import FileManagerRichTextEditorPreview from './FileManagerRichTextEditorPreview';
import FileManagerSpreadsheetPreviewer from './FileManagerSpreadsheetPreviewer';
import { PdfJsPreviewer } from './PdfJsPreviewer';
import { ActionType, ProcessorType, getFileExtension, isDocumentProcessorSupported } from '../../utilities/SupportedFileFormats';

interface Props {
    fileSession: FileSession;
}


export default function FileManagerPreviewer({ fileSession }: Props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fileId = queryParams.get('uuid') ?? "";
    const [currentFileId, setCurrentFileId] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [fileExt, setFileExt] = useState<string>();
    const [refresh, doRefresh] = useState(0);
    const [base64String, setBase64String] = useState<string>();


    useEffect(() => {
        async function fetchSelectedFileContent() {
            const result = await filePreviewService(fileId, fileSession);
            var data = result?.data?.Data;
            if (data) {

                try {
                    if (data.FileName) {
                        setBase64String(data.FileBase64String);
                        setFileName(data.FileName);
                        setCurrentFileId(data.Id);

                        let extension = getFileExtension(data.FileName);
                        if (extension)
                            setFileExt(extension);
                    }

                } catch (e) {
                    console.log(e);
                }
            }
        }
        fetchSelectedFileContent().then(() => { });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ backgroundColor: '#3f3f3f' }}>
            {
                base64String && fileName ?


                    <div style={{ backgroundColor: '#3f3f3f' }} >
                        <div className="sidebar-child" >

                            <div id="card-preview" className="e-card"
                                style={{
                                    backgroundColor: '#3f3f3f',
                                    height: '99vh',
                                    visibility: `${(fileName) ? `visible` : `hidden`}`
                                }}>
                                {
                                    isDocumentProcessorSupported(fileName, ProcessorType.Image, ActionType.Preview) &&

                                    <img
                                        src={
                                            fileExt?.toLowerCase() === ".svg"
                                                ? `data:image/svg+xml;utf8,${encodeURIComponent(atob(base64String))}`
                                                : `data:image/${fileExt?.toLowerCase().substring(1)};base64,${base64String}`
                                        }
                                        alt={fileName}
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            overflowY: 'auto',
                                        }}
                                    />
                                }


                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.PDF, ActionType.Preview)
                                    ) &&

                                    <PdfJsPreviewer pdfPath={encodeURIComponent(currentFileId ?? "")}
                                        fileId={encodeURIComponent(currentFileId ?? "")}
                                        fileName={encodeURIComponent(fileName)}
                                        fileProvider={""}
                                        fileSession={fileSession}
                                        isMaximized={true} />
                                }


                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.Text, ActionType.Preview)) &&

                                    <div style={{
                                        'height': '95vh',
                                        'width': '100%',
                                        'overflowY': 'auto',
                                    }} >

                                        <FileManagerRichTextEditorPreview
                                            html={base64String} />
                                    </div>
                                }

                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.Excel, ActionType.Preview)) &&


                                    <FileManagerSpreadsheetPreviewer
                                        base64FileString={base64String}
                                        fileName={fileName}
                                        fileSession={fileSession} />
                                }

                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.Word, ActionType.Preview)) &&

                                    <div style={{
                                        width: '100%',
                                        overflowY: 'hidden'
                                    }} >
                                            <FileManagerDocumentPreviewer
                                                filePath={currentFileId ?? ""}
                                                fileName={fileName}
                                                refresh={refresh}
                                                fileSession={fileSession}                                            
                                        />
                                    </div>

                                }
                            </div>
                        </div>
                    </div>

                    :

                    <div>Loading please wait...</div>

            }
        </div>
    )
}
