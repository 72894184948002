import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';
import { useEffect, useRef } from 'react';
import { fileOpenDocumentEditorService } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';
import { ActionType, ProcessorType, isDocumentProcessorSupported } from '../../utilities/SupportedFileFormats';

interface Props {
    filePath: string;
    fileName: string;
    refresh: any;
    fileSession: FileSession;
    isMaximized?: boolean;
}

export default function FileManagerDocumentPreviewer({ fileName, filePath, refresh, isMaximized ,fileSession }: Props) {

    const containerRef = useRef<DocumentEditorContainerComponent>(null);

    useEffect(() => {
        var isSupported = isDocumentProcessorSupported(fileName, ProcessorType.Word, ActionType.Preview);
        if (isSupported) {
            const resizeDocumentEditor = () => {
                if (containerRef && containerRef.current) {
                    const containerElement = containerRef.current.element;
                    if (containerElement) {
                        const parentElement = containerElement.parentElement;
                        if (parentElement) {
                            const width = parentElement.clientWidth;
                            const height = parentElement.clientHeight;
                            try {
                                // Resize the container
                                if (containerRef.current.resize) {
                                    containerRef.current.resize(width, height);
                                }
                                if (containerRef.current.documentEditor.fitPage) {
                                    containerRef.current.documentEditor.fitPage('FitPageWidth');
                                }

                                containerRef.current.documentEditor.isReadOnly = true;
                            } catch (error) {
                                console.info('Error resizing the document editor:', error);
                            }
                        }
                    }
                }
            };

            // Initial resize on mount
            resizeDocumentEditor();

            // Handle window resize events
            const handleResize = () => {
                if (containerRef.current) {
                    resizeDocumentEditor();
                }
            };

            window.addEventListener('resize', handleResize);

            // Cleanup resize listener
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);


    useEffect(() => {
        async function loadDocumentFromServer() {
            try {
                if (isDocumentProcessorSupported(fileName, ProcessorType.Word, ActionType.Preview)) {
                    const result = await fileOpenDocumentEditorService(filePath, fileName, fileSession);
                    if (result) {
                        // get the data from the result
                        const documentData = result?.data;
                        if (documentData) {
                            if (containerRef && containerRef.current && containerRef.current.documentEditor) {
                                //JSON.stringify(documentData)
                                containerRef.current.documentEditor.open(documentData);
                                containerRef.current.documentEditor.documentName = fileName;
                                containerRef.current.documentChange = () => {
                                    containerRef?.current?.documentEditor.focusIn();
                                };
                            }
                        }
                    }
                }

            } catch (e) {
                console.log("Document Previewer : " + e);
            }
            finally {

            }
        }

        loadDocumentFromServer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileName]);

    return (
        <div style={{ height: '100%', width: '100%', overflowY: 'hidden', paddingTop: '10px' }}>
            {
                <DocumentEditorContainerComponent
                    id={`container_prev_${filePath}`}
                    autoResizeOnVisibilityChange={true}
                    readOnly={true}
                    ref={containerRef}
                    style={{ display: 'block', height: '100%' }}
                    height={'95vh'}
                    enableToolbar={false}
                    showPropertiesPane={false}
                    locale="en-US"
                />
            }
        </div>
    )
}
