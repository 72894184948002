// Enum for processor types
export enum ProcessorType {
    PDF = 'PDFProcessor',
    Word = 'WordProcessor',
    Excel = 'ExcelProcessor',
    Text = 'TextProcessor',
    Image = 'ImageProcessor'
}

// Enum for actions (Edit or Preview)
export enum ActionType {
    Edit = 'Edit',
    Preview = 'Preview'
}

// Map of supported processors, their corresponding file extensions, and actions
const documentProcessorMap: {
    [key in ProcessorType]: { extension: string, actions: ActionType[] }[]
} = {
    [ProcessorType.PDF]: [{ extension: '.pdf', actions: [ActionType.Edit, ActionType.Preview] }],
    [ProcessorType.Word]: [
        { extension: '.docx', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.doc', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.rtf', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.odt', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.dotm', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.docm', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.dot', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.xml', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.html', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.txt', actions: [ActionType.Edit] }
    ],
    [ProcessorType.Excel]: [
        { extension: '.xlsx', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.ods', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.csv', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.xls', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.xlsb', actions: [ActionType.Edit, ActionType.Preview] }
    ],
    [ProcessorType.Text]: [
        { extension: '.txt', actions: [ActionType.Edit, ActionType.Preview] }
    ],
    [ProcessorType.Image]: [
        { extension: '.jpg', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.ico', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.jpeg', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.jpe', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.bmp', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.gif', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.png', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.webp', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.tif', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.tiff', actions: [ActionType.Edit, ActionType.Preview] },
        { extension: '.svg', actions: [ActionType.Edit, ActionType.Preview] }
    ]
};

// Function to check if the file is supported for the given processor and action
export function isDocumentProcessorSupported(fileName: string, processorType: ProcessorType, actionType: ActionType): boolean {
    // Extract the file extension from the file name
    const fileExtension = getFileExtension(fileName);
    if (!fileExtension)
        return false;

    // Find the processor entry for the given extension
    const processorEntry = documentProcessorMap[processorType]?.find(entry => entry.extension === fileExtension);

    if (!processorEntry) {
        console.warn(`File extension '${fileExtension}' is not supported by '${processorType}': File Name : '${fileName}'.`);
        return false;
    }

    // Check if the action is supported for the given file extension
    const isSupportedAction = processorEntry.actions.includes(actionType);
    if (!isSupportedAction) {
        console.warn(`Action '${actionType}' is not supported for file extension '${fileExtension}' by '${processorType}'.`);
    }

    return isSupportedAction;
}

export function isDocumentSupportedForAnyProcessor(fileName: string, actionType: ActionType): boolean {
    // Extract the file extension from the file name
    const fileExtension = getFileExtension(fileName);

    if (!fileExtension)
        return false;
    // Iterate over each processor type in the map to check if the file extension is supported
    for (const processorType in documentProcessorMap) {
        const processorEntries = documentProcessorMap[processorType as ProcessorType];

        // Check if any of the entries match the file extension and action type
        const processorEntry = processorEntries.find(entry => entry.extension === fileExtension && entry.actions.includes(actionType));

        if (processorEntry) {
            return true; // File is supported by one of the processors for the given action
        }
    }

    console.warn(`File extension '${fileExtension}' is not supported for the action '${actionType}' by any processor.`);
    return false; // File is not supported for the given action by any processor
}


// Function to get image src for supported image formats
export function getImageSrc(fileName: string, base64String: string): string | null {
  
    const fileExtension = getFileExtension(fileName);

    if (!fileExtension)
        return '';

    if (!isDocumentProcessorSupported(fileName, ProcessorType.Image, ActionType.Preview)) {
        return null;
    }

    // Handle different image formats
    switch (fileExtension) {
        case '.svg':
            return `data:image/svg+xml;utf8,${encodeURIComponent(atob(base64String))}`;
        case '.tiff':
        case '.tif':
            console.warn('TIFF images are not natively supported by all browsers.');
            return null;
        case '.gif':
            return `data:image/gif;base64,${base64String}`;
        default:
            return `data:image/${fileExtension.substring(1)};base64,${base64String}`;
    }
}

export function getFileExtension(fileName: string | null | undefined): string | null {
    if (!fileName || typeof fileName !== 'string') {
        console.error("Invalid input: fileName should be a non-empty string.");
        return null;
    }

    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
        // No dot found or the last character is a dot, so no valid extension
        return null;
    }

    return fileName.slice(lastDotIndex).toLowerCase();
}

