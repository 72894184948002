import { AjaxRequestInitiateEventArgs, AjaxRequestSuccessEventArgs, BookmarkView, DownloadEndEventArgs, Inject, Magnification, Navigation, PdfViewerComponent, Print, PrintStartEventArgs, TextSearch, TextSelection, ThumbnailView, Toolbar } from '@syncfusion/ej2-react-pdfviewer';
import { useEffect, useRef, useState } from 'react';
import "../FileManager/FileManagerPDFViewer.scss";

interface Props {
    pdfPath: string;
    pdfName: string;
    fileId: string;
    serviceUrl: string;
    pdfViewerRef: any;
    saveChanges: any;
    setOpen: (value: boolean) => void;
    refresh: any;
}

export default function FileManagerPDFViewer({ pdfPath, pdfName, pdfViewerRef, serviceUrl, refresh, saveChanges, setOpen, fileId }: Props) {

    const pdfViewerRefs = useRef<PdfViewerComponent>(null);
    const [mainArgs, setMainArgs] = useState<AjaxRequestInitiateEventArgs>();


    useEffect(() => {
        handleSavePDF();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveChanges]);

    useEffect(() => {
        performRefresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const handleSavePDF = () => {
        pdfViewerRefs!.current!.serverActionSettings!.download = 'SaveDocument';
        pdfViewerRefs!.current!.download();
        pdfViewerRefs!.current!.serverActionSettings!.download = 'Download';
    }

    const ajaxRequestInitiate = (args: AjaxRequestInitiateEventArgs) => {
        if (pdfViewerRefs && pdfViewerRefs.current && pdfViewerRefs.current.serverActionSettings) {

            args.JsonData['documentPath'] = pdfPath;
            args.JsonData['document'] = pdfPath;
            args.JsonData['selectedPage'] = pdfViewerRefs!.current!.currentPageNumber;
            args.JsonData['pageCount'] = pdfViewerRefs!.current!.pageCount;
            args.JsonData['currentPageNumber'] = pdfViewerRefs!.current!.currentPageNumber;
            args.JsonData['fileId'] = fileId;
            pdfViewerRefs.current.setJsonData(args.JsonData);
            setMainArgs(args);
        }
    }

    const onDownloadEnd = (args: DownloadEndEventArgs) => {
        pdfViewerRefs?.current?.load(pdfPath, "");
        var mainAr = mainArgs;
        setMainArgs(mainAr);
    };

    const onPrintStart = (args: PrintStartEventArgs) => {
        //console.log("Print has started");
    }

    const onFunctionSuccess = (args: AjaxRequestSuccessEventArgs) => {

    }

    const performRefresh = () => {
        
        
        if (pdfViewerRefs && pdfViewerRefs.current) {
          
            pdfViewerRefs.current.updateViewerContainer();

        }
    }

    return (
        <div style = {{ 'height': '90vh' }} >
            <PdfViewerComponent id="pdfViewerId" ref={pdfViewerRefs} name={pdfName} documentPath={pdfPath} downloadEnd={onDownloadEnd} ajaxRequestInitiate={ajaxRequestInitiate} serviceUrl={serviceUrl} ajaxRequestSuccess={onFunctionSuccess} style={{ 'height': '90vh' }} enableMagnification={true} printStart={(e) => onPrintStart(e)}>
                <Inject services={[Toolbar, Magnification, Navigation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch]} />
            </PdfViewerComponent>

        </div>
    )
}
