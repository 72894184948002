// import React from 'react';
// import logo from './logo.svg';

import { EventType } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { Route, Routes } from 'react-router-dom';
import { b2cPolicies } from './authConfig';
import { PageLayout } from './components/PageLayout';
import { Home } from './pages/Home';

import { Provider } from 'react-redux';

import './App.css';
import store from './store/store';
import './styles/App.css';
import { faviconService } from './apis/fileService';

const Pages = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();
    useEffect(() => {
        const callbackId = instance.addEventCallback((event: any) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account!.idTokenClaims!.oid === event.payload.idTokenClaims.oid &&
                                account!.idTokenClaims!.sub === event.payload.idTokenClaims.sub &&
                                account!.idTokenClaims!['tfp'] === b2cPolicies.names.signUpSignIn
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: []
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance]);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

async function setFaviconFromAPI(url: string, defaultFavicon: string) {
    const faviconLink = document.querySelector('link[rel="icon"]') as HTMLLinkElement;

    try {
        //const response = await fetch(url, {
        //    method: 'POST',
        //    headers: {
        //        'Content-Type': 'application/json',
        //    },
        //});
        const result = await faviconService();
        var data = result?.data?.Data;
        //faviconService
        
        if (data) {
           // const data = await response.json();
            let faviconData = data; 

            if (faviconData) {
                // Strip out the extra characters ("b'" and "'")
                faviconData = faviconData.replace(/^b'|'+$/g, '');
                // Set the Base64 data as the favicon
                faviconLink.href = `data:image/x-icon;base64,${faviconData}`;
            } else {
                // If no favicon data, set the default
                faviconLink.href = defaultFavicon;
            }
        } else {
            // If the fetch fails, set the default favicon
            faviconLink.href = defaultFavicon;
        }
    } catch (error) {
        // On error, use the default favicon
        console.log('Failed to fetch favicon:', error);
        faviconLink.href = defaultFavicon;
    }
}

interface AppProps {
    instance?: any;
}

const App = ({ instance }: AppProps) => {
   
    useEffect(() => {
        
        setFaviconFromAPI('https://odoov17-qa.baystream.io/get/favicon/binary', '%PUBLIC_URL%/favicon.ico');
    }, 
     // eslint-disable-next-line react-hooks/exhaustive-deps
    []);



    return (
        //<MsalProvider instance={instance}>

        //</MsalProvider>
        <Provider store={store}>
            <PageLayout>
                <Pages />
            </PageLayout>
        </Provider>
    );
}

export default App;



