import { useEffect, useState } from 'react';


interface Props {
    html: any;
    isMaximized?: boolean;
}
export default function FileManagerRichTextEditor({ html }: Props) {
   // const richtexteditor = createRef<RichTextEditorComponent>();
    const [decodedData, setDecodedData] = useState<string>("");

    useEffect(() => {
        if (html) {

            let data = decodeBase64(html);
            setDecodedData(data);
            //console.log("html loaded");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [html]);

    //const inlineMode: InlineModeModel = {
    //    enable: true,
    //    onSelection: true
    //};

    //const format: FormatModel = {
    //    width: 'auto'
    //};

    //const fontFamily: FontFamilyModel = {

    //};

    //const items: string[] = ['Bold', 'Italic', 'Underline',
    //    'Formats', '-', 'Alignments', 'OrderedList', 'UnorderedList',
    //    'CreateLink'];

    //const toolbarSettings: ToolbarSettingsModel = {
    //    items: items
    //};

    const decodeBase64 = (base64: any) => {

        const text = atob(base64);
        const length = text.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = text.charCodeAt(i);
        }
        const decoder = new TextDecoder(); // default is utf-8
        return decoder.decode(bytes);
    }

    return (
        <>
            {
                (decodedData) &&
                <>
                    <div className="e-card-content" style={{ color: 'white' } }>
                        {decodedData}
                    </div>
                    
                </>
            }
        </>
    )
}
