import { useEffect, useState } from 'react';
import { filePreviewService } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';
import { ActionType, ProcessorType, getImageSrc, isDocumentProcessorSupported } from '../../utilities/SupportedFileFormats';
import FileManagerDocumentPreviewer from './FileManagerDocumentPreviewer';
import FileManagerRichTextEditorPreview from './FileManagerRichTextEditorPreview';
import FileManagerSpreadsheetPreviewer from './FileManagerSpreadsheetPreviewer';
import { PdfJsPreviewer } from './PdfJsPreviewer';

interface Props {
    fileId: string;
    fileSession: FileSession;
}


export default function FileManagerPreviewerDialog({ fileId, fileSession }: Props) {
    const [currentFileId, setCurrentFileId] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [fileExt, setFileExt] = useState<string>();
    const [refresh, doRefresh] = useState(0);
    const [base64String, setBase64String] = useState<string>();


    useEffect(() => {
        async function fetchSelectedFileContent() {
            const result = await filePreviewService(fileId, fileSession);
            var data = result?.data?.Data;
            if (data) {

                try {
                    setBase64String(data.FileBase64String);
                    setFileName(data.FileName);
                    setCurrentFileId(data.Id);
                    setFileExt("." + data.FileName.split('.').pop() ?? "");
                } catch (e) {
                    console.log(e);
                }
            }
        }
        fetchSelectedFileContent().then(() => { });
    }, []);




    return (
        <div style={{ backgroundColor: '#3f3f3f' }}>
            {
                base64String ?


                    <div style={{ backgroundColor: '#3f3f3f' }} >
                        <div className="sidebar-child" >

                            <div id="card-preview" className="e-card"
                                style={{
                                    backgroundColor: '#3f3f3f',
                                    height: '99vh',
                                    visibility: `${(fileName) ? `visible` : `hidden`}`
                                }}>
                                {
                                    fileName &&
                                    (isDocumentProcessorSupported(fileName, ProcessorType.Image, ActionType.Preview)) &&

                                    <img
                                        src={getImageSrc(fileName, base64String)??""
                                        }
                                        alt={fileName}
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            overflowY: 'auto',
                                        }}
                                    />
                                }


                                {
                                    (fileName
                                        && (isDocumentProcessorSupported(fileName, ProcessorType.PDF, ActionType.Preview))
                                    ) &&

                                    <PdfJsPreviewer pdfPath={encodeURIComponent(currentFileId ?? "")}
                                        fileId={encodeURIComponent(currentFileId ?? "")}
                                        fileName={encodeURIComponent(fileName)}
                                        fileProvider={""}
                                        fileSession={fileSession}
                                        isMaximized={true} />
                                }


                                {
                                    (fileName
                                        && fileExt?.toLowerCase() === ".txt") &&

                                    <div style={{
                                        'height': '100%',
                                        'width': '100%',
                                        'overflowY': 'auto',
                                    }} >

                                        <FileManagerRichTextEditorPreview
                                            html={base64String} />
                                    </div>
                                }

                                {
                                    (fileName
                                        && (isDocumentProcessorSupported(fileName, ProcessorType.Excel, ActionType.Preview))) &&


                                    <FileManagerSpreadsheetPreviewer
                                        base64FileString={base64String}
                                        fileName={fileName}
                                        fileSession={fileSession} />
                                }

                                {
                                    (fileName
                                        && (isDocumentProcessorSupported(fileName, ProcessorType.Word, ActionType.Preview))) &&

                                    <div style={{
                                        width: '100%',
                                        overflowY: 'hidden'
                                    }} >
                                        <FileManagerDocumentPreviewer
                                            filePath={currentFileId ?? ""}
                                            fileName={fileName}
                                            refresh={refresh}
                                            fileSession={fileSession}
                                            isMaximized={true}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    :

                    <div>Loading please wait...</div>

            }
        </div>
    )
}
