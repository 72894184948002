import { Split } from '@geoffcox/react-splitter';
import { Grid } from '@mui/material';
import { ToastUtility } from '@syncfusion/ej2-notifications';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import * as pdfjs from "pdfjs-dist";
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { fileOpenService, filePDFEditPdfAsync, fileSplitPDFService } from '../../apis/fileService';
import { FilePageItem } from '../../models/FilePageItem';
import { FileSession } from '../../models/FileSession';
import { SplitPdfFile } from '../../models/SplitPdfFile';
import "../FileManager/PdfEditorPage.scss";
import NavBar from '../NavBar';
import FileManagerStyledDialog from './FileManagerStyledDialog';

interface Props {
    fileSession: FileSession;
}

export const PdfManagerPage = ({ fileSession }: Props) => {
    //const pdfjs = require('pdfjs-dist');
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const pdfPath = queryParams.get('path') ?? "";
    const fileName = queryParams.get('name') ?? "";
    const fileId = queryParams.get('name') ?? "";
    const fileProvider = queryParams.get('provider') ?? "";

    const [saveSplitChanges, setSaveSplitChanges] = useState<boolean>(false);
    const [checked, setChecked] = React.useState<number[]>();
    const [gridHeight, setGridHeight] = React.useState<number>(200);
    const [sliderValue, setSliderValue] = React.useState<number>(0);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [hoveredPageNumber, setHoveredPageNumber] = React.useState<number>(0);
    const [pdfPageCount, setPdfPageCount] = React.useState<number>(0);
    const [fileDeleteDialogVisibility, setFileDeleteDialogVisibility] = useState<boolean>(false);

    const [isDocumentBusy, setIsDocumentBusy] = useState<boolean>(false);

    const isMediumOrLarger = useMediaQuery({ minWidth: 150 });
    const [minPrimarySize, setMinPrimarySize] = useState('18%');
    const [currentPrimarySize, setCurrentPrimarySize] = useState('');
    const [primaryMinimumSize, setPrimaryMinimumSize] = useState('18%');
    const sideThumbnailsRef = useRef<(HTMLDivElement | null)[]>([]);
    const mainThumbnailsRef = useRef<(HTMLDivElement | null)[]>([]);
    const [zoomValue, setZoomValue] = useState(80);

    const [filePageItems, setFilePageItems] = React.useState<FilePageItem[]>([]);
    const displayedItems = filePageItems
        .filter(item => !item.Deleted)
        .sort((a, b) => a.PageNumber - b.PageNumber);
    var pdfjsLib: any;
    pdfjsLib = pdfjs;
    pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.js";
    const [base64FileString, setBase64FileString] = useState<string>();
    const [past, setPast] = useState<FilePageItem[][]>([]);
    const [future, setFuture] = useState<FilePageItem[][]>([]);



    const editCommands = filePageItems.map(item => {
        let commandType = item.Deleted ? 'delete' : 'rotate';
        if (item.PageNumber !== item.OldPageNumber) {
            commandType = 'move';
        }
        return {
            Type: commandType,
            PageNumber: item.PageNumber,
            OldPageNumber: item.OldPageNumber,
            Degrees: item.Rotation,
        };
    });

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel("files-channel");
        broadcastChannel.onmessage = (event) => {
            const { sessionId, host, uid } = event.data;

            localStorage.setItem('session-id', sessionId);
            localStorage.setItem('session-host', host);
            localStorage.setItem('session-uid', uid);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let emptyNumberArray: number[] = [];
        setSelectedIndex(0);
        setChecked(emptyNumberArray);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (selectedIndex && sideThumbnailsRef && sideThumbnailsRef.current) {
            try {
                let moveTo = (selectedIndex + 1);
                sideThumbnailsRef?.current[moveTo]?.scrollIntoView({ behavior: "smooth", block: "start" });
                mainThumbnailsRef?.current[moveTo]?.scrollIntoView({ behavior: "smooth", block: "start" });

            } catch (e) {
                console.log(e);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);



    useEffect(() => {
        if (pdfPath && base64FileString) {
            const loadingTask = pdfjs.getDocument(`data:application/pdf;base64,${base64FileString}`);
            showToast("Please wait...", "Document Loading",
                "e-toast-info");
            loadingTask.promise.then(function (pdfDocument: any) {
                // Get number of pages
                const numPages = pdfDocument.numPages;
                setPdfPageCount(numPages);
                let tempArray: FilePageItem[] = [];

                // Loop through all pages
                for (let i = 1; i <= numPages; i++) {
                    // Get page i
                    pdfDocument.getPage(i).then(function (pdfPage: any) {
                        // Get viewport at scale 3
                        const viewport = pdfPage.getViewport({ scale: 3 });
                        // Create canvas element for page i
                        const canvas = document.createElement('canvas');
                        canvas.id = 'canvas' + i;
                        // Get canvas context
                        const context = canvas.getContext('2d');
                        // Set canvas dimensions to match viewport size
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;
                        // Render page on canvas
                        pdfPage.render({
                            canvasContext: context,
                            viewport: viewport,
                        }).promise.then(function () {
                            // Create a new FilePageItem object with constructor parameters
                            let filePageItem = new FilePageItem(
                                i.toString(), // Id
                                base64FileString, // FileId
                                '', // ImageData (will be assigned later)
                                null, // PageData (will be assigned later)
                                fileName, // FileName
                                i, // PageNumber
                                numPages, // PageCount
                                viewport.width, // Width
                                viewport.height, // Height
                            );
                            // Get base64 string of image data and assign it to ImageData property
                            filePageItem.ImageData = canvas.toDataURL();
                            // Get blob data of canvas and assign it to PageData property using a callback function
                            canvas.toBlob(function (blob) {
                                filePageItem.PageData = blob;
                                // Push filePageItem to tempArray
                                tempArray.push(filePageItem);
                                // If tempArray length is equal to numPages, update state variable with tempArray
                                if (tempArray.length === numPages) {
                                    let sortedArray = tempArray.sort((a, b) => a.PageNumber - b.PageNumber)
                                    setFilePageItems(sortedArray);
                                }
                            });
                        });
                    });
                }
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64FileString]);


    useEffect(() => {

        if (isDocumentBusy) {
            showToast("Please wait...", "Document Loading",
                "e-toast-info");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDocumentBusy]);


    useEffect(() => {
        async function loadFileFromServer() {
            try {
                // make a fetch request to the action method URL
                const result = await fileOpenService(pdfPath, fileName, fileProvider ?? "", fileSession);
                var data = result?.data?.Data;
                if (data) {
                    setBase64FileString(data.FileBase64String);

                }
            } catch (error) {
                // handle any errors that may occur
                console.log('There has been a problem with your fetch operation: ', error);
            }
        }

        loadFileFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Saves data when saveSplitChanges is changed
    useEffect(() => {

        async function fetchSelectedFileContent() {
            if (checked && checked.length > 0 && saveSplitChanges === true) {
                showToast("Splitting the pages", "Split Document", "e-toast-info");
                var currentName = fileName;
                var currentNameWithoutExt = currentName.substring(0, currentName.lastIndexOf("."));
                let checkedPages: string[] = [];
                let checkedPagesNames: string[] = [];
                let counter = 0;
                checked.sort().forEach((a) => {
                    counter++;
                    checkedPages.push((a + 1).toString());
                    checkedPagesNames.push(`${currentNameWithoutExt}-${counter}`);
                });
                checkedPagesNames.push(`${currentNameWithoutExt}-${counter + 1}`);
                const splitPdfFileRequest = new SplitPdfFile(currentName, pdfPath, checkedPages, checkedPagesNames);

                if (checkedPages && checkedPages.length > 0) {
                    try {
                        const result = await fileSplitPDFService(splitPdfFileRequest, fileProvider, fileSession);
                        if (result.data) {
                            if (result.data.Success) {
                                showToast("File splitted successfully", "Split Document", "e-toast-success");
                                //window.location.assign('/');
                                window.location.href = '/'
                            }
                            else {
                                showToast("An error occurred. Please refresh and try again", "Split Document", "e-toast-danger");
                            }
                        }

                    } catch (e) {
                    }
                }
                else {
                    showToast("Please select pages to split", "Split Document", "e-toast-danger");
                }
            }
        }
        fetchSelectedFileContent().then(
            () => {
                setSaveSplitChanges(false);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveSplitChanges]);


    const rotateItem = async (id: string, degrees: number) => {
        // Find the item and rotate it

        const newItems = await Promise.all(filePageItems.map(async item => {
            if (item.Id === id) {

                if (item.Rotation === -1)
                    item.Rotation = 0;

                // Assuming you have a method to rotate the ImageData
                let newImageData = await rotateImageData(item.ImageData, degrees);
                // Create a new instance of FilePageItem with the rotated image data
                const normalizedRotation = ((item.Rotation + degrees) % 360 + 360) % 360; // This makes sure the result is positive
                const allowedRotations = [0, 90, 180, 270];
                const closestRotation = allowedRotations.reduce((prev, curr) =>
                    Math.abs(curr - normalizedRotation) < Math.abs(prev - normalizedRotation) ? curr : prev
                );

                return new FilePageItem(
                    item.Id,
                    item.FileId,
                    newImageData ?? "",
                    item.PageData,
                    item.FileName,
                    item.PageNumber,
                    item.PageCount,
                    item.Height,
                    item.Width,
                    closestRotation
                );
            }
            return item;
        }));

        setPast([...past, filePageItems]); // Add the current state to the past
        setFilePageItems(newItems); // Update the current state
        setFuture([]); // Clear the future when making a new change
    };


    const moveItem = (id: number, direction: 'up' | 'down') => {
        // Find the item with the given ID
        const index = filePageItems.findIndex(item => item.PageNumber === id);
        if (index === -1) return; // Item not found

        // Determine the new index based on the direction
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= filePageItems.length) return; // Cannot move beyond list boundaries

        // Create a copy of the current items
        const newItems = [...filePageItems];

        // Swap the item with its neighbor
        [newItems[index], newItems[newIndex]] = [newItems[newIndex], newItems[index]];

        // Update the PageNumber and OldPageNumber properties of each item
        newItems.forEach((item, i) => {
            if (item.PageNumber !== i + 1) {
                item.OldPageNumber = item.PageNumber;
            }
            item.PageNumber = i + 1;
        });

        setPast([...past, filePageItems]); // Add the current state to the past
        setFilePageItems(newItems); // Update the current state
        setFuture([]); // Clear the future when making a new change
    };


    const removeItem = async (id: string) => {
        // Find the item with the given ID
        const index = filePageItems.findIndex(item => item.Id === id);
        if (index === -1) return; // Item not found

        // Mark the item as deleted
        const newItems = [...filePageItems];
        newItems[index] = { ...newItems[index], Deleted: true };

        // Update the PageNumber property of each remaining item
        newItems.forEach((item, i) => {
            item.PageNumber = i + 1;
        });

        // Sort the items by PageNumber
        newItems.sort((a, b) => a.PageNumber - b.PageNumber);

        setPast([...past, filePageItems]); // Add the current state to the past
        setFilePageItems(newItems); // Update the current state
        setFuture([]); // Clear the future when making a new change
        setFileDeleteDialogVisibility(false);
    };


    const undo = () => {
        if (past.length > 0) {
            const previous = past[past.length - 1];
            setPast(past.slice(0, past.length - 1)); // Remove the last state from the past
            setFuture([filePageItems, ...future]); // Add the current state to the future

            // Sort the items by PageNumber
            const sortedItems = [...previous].sort((a, b) => a.PageNumber - b.PageNumber);
            setFilePageItems(sortedItems); // Set the current state to the previous state
        }
    };

    const redo = () => {
        if (future.length > 0) {
            const next = future[0];
            setFuture(future.slice(1)); // Remove the first state from the future
            setPast([...past, filePageItems]); // Add the current state to the past

            // Sort the items by PageNumber
            const sortedItems = [...next].sort((a, b) => a.PageNumber - b.PageNumber);
            setFilePageItems(sortedItems); // Set the current state to the next state
        }
    };

    const rotateImageData = (base64Image: string, degrees: number) => {
        return new Promise<string>((resolve, reject) => {
            // Create an image element
            const img = new Image();

            img.onload = function () {
                // Calculate the size of the canvas
                const radians = degrees * Math.PI / 180;
                const sin = Math.sin(radians);
                const cos = Math.cos(radians);
                const newWidth = Math.abs(img.width * cos) + Math.abs(img.height * sin);
                const newHeight = Math.abs(img.width * sin) + Math.abs(img.height * cos);

                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = newWidth;
                canvas.height = newHeight;
                if (ctx) {
                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate(degrees * Math.PI / 180);
                    ctx.drawImage(img, -img.width / 2, -img.height / 2);
                    resolve(canvas.toDataURL());
                }
            };

            img.onerror = reject;

            // Set the source of the image
            img.src = base64Image;
        });
    }

    //Checks or unchecks a page for spit function
    const handleToggle = (value: number) => () => {
        var checkeds = checked ?? [];

        const currentIndex = checkeds.indexOf(value);
        const newChecked = [...checkeds];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    //works to resize the viwer when split is changed
    const onSplitChanged = (primarySize: string) => {
        setCurrentPrimarySize(primarySize);

        let size = Math.floor(parseFloat(primarySize));
        let minSize = Math.floor(parseFloat(minPrimarySize));
        if (size > minSize) {
            setMinPrimarySize("15%");
        }
    };

    //Updates the SaveSplitChanges
    const handleSplitSaveChanges = () => {

        if (!checked || checked.length < 1) {
            return;
        }
        setSaveSplitChanges(true);
    }



    //resizes thumbnails
    const handleSteppedSliderChange = (args: number) => {
        var newValue = args;
        if (typeof newValue === 'number') {
            if (newValue !== sliderValue) {
                if (newValue > sliderValue) {
                    setGridHeight(gridHeight + ((newValue - sliderValue) * 3.2));
                }
                else {
                    setGridHeight(gridHeight - ((sliderValue - newValue) * 3.2));
                }
            }

            let size = Math.floor(parseFloat(currentPrimarySize));
            let minSize = Math.floor(parseFloat(primaryMinimumSize));
            if (newValue === 50) {
                if (size <= minSize) {
                    setPrimaryMinimumSize(`${20}%`);
                }
                else {
                    setMinPrimarySize("15%");
                }
            }
            else if (newValue === 100) {
                if (size <= minSize) {
                    setPrimaryMinimumSize(`${25}%`);
                }
                else {
                    setMinPrimarySize("15%");
                }
            }
            else {
                setPrimaryMinimumSize(`${15}%`);
                setMinPrimarySize("15%");
            }
            setSliderValue(newValue);
        }
    };

    //Checks whether a page is selected
    const isPageChecked = (value: number) => {
        if (checked) {
            return checked.indexOf(value) !== -1;
        }
        else {
            return false;
        }
    }

    //Moves page up one step
    const handleMoveUp = (selectedPage: number) => {
        moveItem((selectedPage), "up");
    };

    //Moves page down one step
    const handleMoveDown = (selectedPage: number) => {
        moveItem((selectedPage), "down");
    }



    //Rotates a page RotateAngle90
    const handleRotateRight = () => {
        rotateItem((selectedIndex + 1).toString(), 90);
    }

    //Rotates a page RotateAngle270
    const handleRotateLeft = () => {
        rotateItem((selectedIndex + 1).toString(), -90);
    }

    //Rotates a page RotateAngle0
    const handleUndo = () => {
        undo();
    }

    //Rotates a page RotateAngle180
    const handleRedo = () => {
        redo();
    }

    //Deletes a page
    const handleDeletePage = () => {
        removeItem((selectedIndex + 1).toString());
    }


    //gets the saved color for dynamic display on selected items
    const getTextColor = () => {
        //let strFontColor = localStorage?.getItem('selectedColor')?.toString();
        //if (strFontColor) {
        //    return strFontColor;
        //}
        return "black";
    }


    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 4000,
            cssClass: cssClass,

        });
    }

    //check orientation of a thumbnail
    const getComputedDimension = (height: number, width: number, containerHeight: number, isHeight: boolean) => {
        if (isHeight) {
            return height * containerHeight / Math.max(height, width);
        } else {
            return width * containerHeight / Math.max(height, width);
        }
    }

    const handleMouseEnter = (pageNumber: number) => {
        setHoveredPageNumber(pageNumber);
    }

    const handleMouseLeave = (pageNumber: number) => {
        setHoveredPageNumber(0);
    }

    const canRearrange = (pageNumber: number) => {
        if (pageNumber === hoveredPageNumber) {
            return true;
        }
        return false;
    }

    const handleZoomChange = (change: number) => {
        setZoomValue(prevValue => {
            const newValue = prevValue + change;
            if (newValue >= 70 && newValue <= 100) {
                return newValue;
            }
            return prevValue;
        });
    };

    const savePendingChanges = () => {
        async function doAsyncFunction() {
            setIsDocumentBusy(true);
            try {
                const result = await filePDFEditPdfAsync(pdfPath, fileName, fileId, fileProvider, editCommands, fileSession);
                if (result.data) {
                    if (result.data.Success) {
                        showToast("Saved Changes", "Edit Document", "e-toast-success");
                    }
                    else {
                        showToast("Error occurred while saved changes", "Edit Document", "e-toast-danger");
                    }
                }

            } catch (e) {
            }
        }
        doAsyncFunction().then(
            () => {
                setIsDocumentBusy(false);
            }
        );
    }


    let thumbnailElements: JSX.Element[] = [];

    for (let thumbnail of displayedItems) {
        // Check if thumbnail with the same PageNumber already exists in thumbnailElements
        const pageNumberExists = thumbnailElements.some(
            element => (element.props.PageNumber === thumbnail.PageNumber)
        );

        if (!pageNumberExists) {
            // Only push thumbnail if PageNumber doesn't already exist

            thumbnailElements.push(
                // your JSX here

                <Grid item key={thumbnail.Id}
                    className="pdf-page-thumbnail"
                    style={{
                        height: `${gridHeight + 50}px`,
                        width: `${gridHeight + 50}px`,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    ref={(el) => (sideThumbnailsRef.current[thumbnail.PageNumber] = el!)}
                >

                    <div
                        onMouseEnter={() => handleMouseEnter(thumbnail.PageNumber)}
                        onMouseLeave={() => handleMouseLeave(thumbnail.PageNumber)}

                        style={{
                            padding: '5px',
                            display: 'flex',
                            flexDirection: 'row',

                        }}>

                        <div style={{
                            height: `${gridHeight}px`,
                            width: `${gridHeight}px`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => {
                                setSelectedIndex(thumbnail.PageNumber - 1);
                            }}>
                            <div className="e-card"
                                style={{

                                    width: `${getComputedDimension(thumbnail.Height, thumbnail.Width, gridHeight - 30, false)}px`,
                                    height: `${getComputedDimension(thumbnail.Height, thumbnail.Width, gridHeight - 30, true)}px`,
                                    padding: '2px',
                                    backgroundColor: `${selectedIndex === thumbnail.PageNumber - 1 ? '#694b61' : 'transparent'}`,
                                }}>

                                <img
                                    id={`img-${Math.random().toString(36).slice(2, 7)}`}
                                    src={thumbnail.ImageData}
                                    alt={(thumbnail.PageNumber).toString()}
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '30px',
                                        padding: '3px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '30%',
                                            height: '20px',
                                            lineHeight: '20px',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            color: `${selectedIndex === thumbnail.PageNumber - 1 ? '#694b61' : getTextColor()
                                                }`,
                                        }}
                                    >
                                        {thumbnail.PageNumber}
                                    </div>
                                </div>

                                {canRearrange(thumbnail.PageNumber) && (

                                    <div className="e-toolbar-items e-tbar-pos">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '30px',
                                                    padding: '3px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {
                                                    (thumbnail.PageNumber !== 1) &&
                                                    <div className="e-toolbar-item">
                                                        <ButtonComponent
                                                            disabled={isDocumentBusy}
                                                            iconCss="e-icons e-chevron-up-double"
                                                            className="e-tbar-btn e-tbtn-txt 
                                                            e-control e-btn e-lib e-popup-btn"
                                                            title="Move up"
                                                            iconPosition="Left"
                                                            onClick={() => {
                                                                handleMoveUp(thumbnail.PageNumber);
                                                            }}
                                                        ></ButtonComponent>
                                                    </div>
                                                }

                                                {
                                                    (thumbnail.PageNumber !== pdfPageCount && filePageItems?.length > 1) &&
                                                    <div className="e-toolbar-item">
                                                        <ButtonComponent
                                                            disabled={isDocumentBusy}
                                                            iconCss="e-icons e-chevron-down-double"
                                                            className="e-tbar-btn e-tbtn-txt 
                                                            e-control e-btn e-lib e-popup-btn"
                                                            title="Move down"
                                                            iconPosition="Left"
                                                            onClick={() => {
                                                                handleMoveDown(thumbnail.PageNumber);
                                                            }}
                                                        ></ButtonComponent>
                                                    </div>
                                                }
                                                {
                                                    (displayedItems?.length > 1) &&
                                                    <div className="e-toolbar-item">
                                                        <ButtonComponent
                                                            disabled={isDocumentBusy}
                                                            iconCss="e-icons e-delete"
                                                            title="Delete"
                                                            className="e-tbar-btn e-tbtn-txt 
                                                        e-control e-btn e-lib e-popup-btn"
                                                            iconPosition="Left"
                                                            onClick={() => {
                                                                setFileDeleteDialogVisibility(true);
                                                                setSelectedIndex(thumbnail.PageNumber - 1);
                                                            }}
                                                        ></ButtonComponent>
                                                    </div>
                                                }

                                                {
                                                    (displayedItems?.length > 1) &&
                                                    <div className="e-toolbar-item">
                                                        <div style={{ textAlign: 'center', width: '20px' }}>
                                                            {thumbnail.PageNumber}
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>

                        </div>
                        <div style={{
                            width: '20px',
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                            title="Split"
                            onClick={handleToggle(thumbnail.PageNumber - 1)}
                        >
                            {
                                ((thumbnail.PageNumber) < pdfPageCount) ?
                                    <div className="page-divider"
                                        style={{ 'height': `${gridHeight}px` }}>
                                        <div
                                            className="cut-divider" >
                                            {
                                                isPageChecked(thumbnail.PageNumber - 1) ?
                                                    <>
                                                        <div className="cutted-page"></div>
                                                        <span className="e-icons e-cut"></span>
                                                        <div className="cutted-page"></div>
                                                    </> :
                                                    <>
                                                        <div className="uncutted-page"></div>
                                                    </>
                                            }

                                        </div>
                                    </div> :
                                    <div className="page-divider"
                                        style={{ 'height': `${gridHeight}px` }}>

                                    </div>
                            }
                        </div>

                    </div>

                </Grid>
            );
        }
    }

    return (
        <div >
            <NavBar filePath={pdfPath} fileName={fileName} saveFunction={() => { savePendingChanges(); }
            } saveAsFunction={() => { }} cancelFunction={() => {
                    if (window.opener) {
                        window.close();
                    } else {
                        window.location.href = 'about:blank';
                        window.close();
                    }
                }}
                closeFunction={() => {
                    if (window.opener) {
                        window.close();
                    } else {
                        window.location.href = 'about:blank';
                        window.close();
                    }
                }} />
            <div id="pdf-editor-page" style={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 70px)` }}>
                <div
                    id="editor-header"
                    className="e-control e-toolbar e-lib e-keyboard e-toolpop"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px 0',
                        gap: '10px',
                        height: 'auto',
                    }}
                >
                    <div
                        className="e-toolbar-items e-tbar-pos"
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            className="toolbar-left"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                alignItems: 'center',
                            }}
                        >
                            {checked && checked.length > 0 && (
                                <div className="e-toolbar-item">
                                    <ButtonComponent
                                        disabled={!(checked && checked.length > 0)}
                                        iconCss="e-icons e-cut"
                                        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                        onClick={handleSplitSaveChanges}
                                        title="Apply Split Changes"
                                        iconPosition="Left"
                                    >
                                        Split into {checked.length + 1} Files
                                    </ButtonComponent>
                                </div>
                            )}

                            <div className="e-toolbar-item">
                                <ButtonComponent
                                    disabled={isDocumentBusy}
                                    iconCss="e-icons e-rotate-right"
                                    className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                    iconPosition="Left"
                                    title="Rotate Right"
                                    onClick={handleRotateRight}
                                >
                                    Rotate Right
                                </ButtonComponent>
                            </div>

                            <div className="e-toolbar-item">
                                <ButtonComponent
                                    disabled={isDocumentBusy}
                                    iconCss="e-icons e-rotate-left"
                                    className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                    iconPosition="Left"
                                    title="Rotate Left"
                                    onClick={handleRotateLeft}
                                >
                                    Rotate Left
                                </ButtonComponent>
                            </div>

                            <div
                                className="e-toolbar-item"
                                style={{ pointerEvents: past?.length > 0 ? 'auto' : 'none' }}
                            >
                                <ButtonComponent
                                    disabled={isDocumentBusy}
                                    iconCss="e-icons e-action-undo"
                                    className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                    iconPosition="Left"
                                    title="Undo"
                                    onClick={handleUndo}
                                >
                                    Undo
                                </ButtonComponent>
                            </div>

                            <div
                                className="e-toolbar-item"
                                style={{ pointerEvents: future?.length > 0 ? 'auto' : 'none' }}
                            >
                                <ButtonComponent
                                    disabled={isDocumentBusy}
                                    iconCss="e-icons e-action-redo"
                                    className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                    iconPosition="Left"
                                    title="Redo"
                                    onClick={handleRedo}
                                >
                                    Redo
                                </ButtonComponent>
                            </div>
                        </div>

                        <div
                            className="toolbar-right"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                alignItems: 'center',
                            }}
                        >
                            <div className="e-toolbar-item">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '5px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="split-pages-title">Thumbnail Size</div>
                                    <div className="e-btn-group">
                                        <input
                                            type="radio"
                                            id="left"
                                            defaultChecked={sliderValue === 0}
                                            name="align"
                                            value="left"
                                            key="left"
                                        />
                                        <label
                                            className="e-btn"
                                            onClick={() => handleSteppedSliderChange(0)}
                                            htmlFor="left"
                                        >
                                            Small
                                        </label>
                                        <input
                                            type="radio"
                                            id="center"
                                            defaultChecked={sliderValue === 50}
                                            name="align"
                                            value="center"
                                            key="center"
                                        />
                                        <label
                                            className="e-btn"
                                            onClick={() => handleSteppedSliderChange(50)}
                                            htmlFor="center"
                                        >
                                            Medium
                                        </label>
                                        <input
                                            type="radio"
                                            id="right"
                                            defaultChecked={sliderValue === 100}
                                            name="align"
                                            value="right"
                                            key="right"
                                        />
                                        <label
                                            className="e-btn"
                                            onClick={() => handleSteppedSliderChange(100)}
                                            htmlFor="right"
                                        >
                                            Large
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="e-toolbar-item">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '5px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="split-pages-title">Zoom</div>
                                    <div className="e-btn-group">
                                        <button
                                            className="e-btn"
                                            onClick={() => handleZoomChange(-5)}
                                            disabled={zoomValue <= 50}
                                        >
                                            Zoom Out
                                        </button>
                                        <button
                                            className="e-btn"
                                            onClick={() => handleZoomChange(5)}
                                            disabled={zoomValue >= 100}
                                        >
                                            Zoom In
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="e-card" style={{ height: `calc(100vh - 100px)`, width: '100%' }}>
                    <Split
                        initialPrimarySize={minPrimarySize}
                        minSecondarySize="30%"
                        minPrimarySize={primaryMinimumSize}
                        onSplitChanged={onSplitChanged}
                    >
                        {/* Conditional rendering based on screen size */}
                        {isMediumOrLarger && (
                            <div>
                                <div className="page-previews">
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginTop: '6px',
                                            justifyContent: 'center',
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            padding: '10px',
                                            height: 'auto',
                                            minWidth: '130px',
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction={{ xs: 'column', md: 'row' }}
                                            spacing={5}
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ marginTop: '3px', height: '82vh' }}
                                        >
                                            {displayedItems && <>{thumbnailElements}</>}
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div
                            id="editor-content"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'inherit',
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    width: '100vw',
                                    margin: '0 auto',
                                    overflowY: 'auto',
                                }}
                            >
                                {displayedItems.map((filePage) => (
                                    <div
                                        key={filePage.Id.toString()}
                                        ref={(el) => (mainThumbnailsRef.current[filePage.PageNumber] = el!)}
                                        style={{
                                            margin: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => {
                                            setSelectedIndex(filePage.PageNumber - 1);
                                        }}
                                    >
                                        <img
                                            id={`img-${Math.random().toString(36).slice(2, 7)}`}
                                            src={filePage.ImageData}
                                            alt={filePage.PageNumber.toString()}
                                            style={{
                                                objectFit: 'contain',
                                                width: `${zoomValue}%`,
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Split>
                </div>

                <FileManagerStyledDialog visible={fileDeleteDialogVisibility}
                    size={"sxx"}
                    target='#pdf-editor-page'
                    isSaving={false}
                    title={"Delete"}
                    canResize={false}
                    allowDragging={false}
                    showSaveButton={false}
                    setOpen={function (value: boolean): void {
                        setFileDeleteDialogVisibility(value);
                    }}

                    saveChanges={async function (value: boolean): Promise<void> {

                    }}

                    buttons={[
                        {
                            buttonModel: {
                                content: 'Cancel',
                                iconCss: 'e-icons e-close-dialog',
                                isPrimary: false,
                                cssClass: 'e-flat toolbar-button'
                            },
                            click: () => setFileDeleteDialogVisibility(false)
                        },
                        {
                            buttonModel: {
                                content: 'Yes',
                                iconCss: 'e-icons e-ok-check-mark',
                                cssClass: 'e-flat toolbar-button',
                                isPrimary: true
                            },
                            click: () => handleDeletePage()
                        }
                    ]}

                >
                    <>

                        <div style={{
                            padding: '5px',
                            marginRight: '10px',
                            display: 'flex',
                            height: 'auto',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} >
                            <div>
                                Are you sure you want to delete this page?
                            </div>
                        </div>

                    </>

                </FileManagerStyledDialog>
            </div>


        </div>
    )
};