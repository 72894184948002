import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import FileManagerCore from './FileManager/FileManagerCore';
import FileManagerDocumentEditor from './FileManager/FileManagerDocumentEditor';
import FileManagerImageEditor from './FileManager/FileManagerImageEditor';
import FileManagerSpreadsheetEditor from './FileManager/FileManagerSpreadsheetEditor';
import { PdfManagerPage } from './FileManager/PdfManagerPage';
import "./PageLayout.scss";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileSession } from '../models/FileSession';
import { NavigationBar } from './NavigationBar';
import Background from '../images/FileManagerMain.png';
import { ODOO_URL } from '../environment/env_dev';
import FileManagerPreviewer from './FileManager/FileManagerPreviewer';

interface Props {
    children: any;
}

const outerDivStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '95vh',
    width: '100vw'
};

const innerDivStyle: React.CSSProperties = {
    color: 'black'
};

export const PageLayout = ({ children }: Props) => {

    const [fileSession, setFileSession] = useState<FileSession>();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const { instance } = useMsal();

    useEffect(() => {
        if (!fileSession) {
            let sessionId = queryParams.get('sessionId') ?? "";
            let sessionHost = queryParams.get('sessionHost') ?? "";
            let sessionUid = queryParams.get('sessionUid') ?? "";
            let sessionB2C = queryParams.get('userName') ?? queryParams.get('sessionB2C') ?? "";
            // Check if parameters are present in the URL
            if (sessionId) {
                setFileSession(new FileSession(sessionId, sessionHost, sessionUid, sessionB2C));

                // Save parameters to local storage
                localStorage.setItem('sessionId', sessionId);
                localStorage.setItem('sessionHost', sessionHost);
                localStorage.setItem('sessionUid', sessionUid);
                localStorage.setItem('sessionB2C', sessionB2C);

                // Remove parameters from the URL
                queryParams.delete('sessionId');
                queryParams.delete('sessionUid');
                queryParams.delete('sessionHost');
                queryParams.delete('userName');
                queryParams.delete('sessionB2C');
                navigate('?' + queryParams.toString(), { replace: true });
            }
            else {
                let storedSessionId = localStorage.getItem('sessionId');
                let storedSessionHost = localStorage.getItem('sessionHost');
                let storedSessionUid = localStorage.getItem('sessionUid');
                let storedSessionB2C = localStorage.getItem('sessionB2C');
                if (storedSessionId && storedSessionHost && storedSessionUid && storedSessionB2C) {
                    setFileSession(new FileSession(storedSessionId, storedSessionHost, storedSessionUid, storedSessionB2C));
                }
                else if (storedSessionB2C) {
                    /*setFileSession(new FileSession('default', 'default', '1', storedSessionB2C));*/
                    setFileSession(new FileSession('default', 'default', '1', storedSessionB2C));
                }
                else if (!storedSessionId) {
                    if (ODOO_URL )
                        window.location.replace(ODOO_URL);
                    else
                        setFileSession(new FileSession('default', 'default', '1', "default"));
                }
                else {
                    if (instance) {
                        let activeAccount = instance?.getActiveAccount();

                        if (activeAccount) {

                            const tokenRequest = {
                                scopes: ["openid", "offline_access"]
                            };
                            // Try to get the access token silently from the cache
                            instance.acquireTokenSilent(tokenRequest).then(response => {
                                let accessToken = response.idToken;

                                if (accessToken) {
                                    setFileSession(new FileSession('default', 'default', '1', accessToken));
                                    localStorage.setItem('sessionB2C', accessToken);
                                }

                            }).catch(err => {
                                console.log("Application Auth Error :: " + err)
                            });
                        }
                    }
                }

            }


        }

    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);


    useEffect(() => {
        return () => {
            localStorage.removeItem('sessionId');
            localStorage.removeItem('sessionHost');
            localStorage.removeItem('sessionUid');
            localStorage.removeItem('sessionB2C');
        };
    }, []);


    useEffect(() => {
        const handler = (messageEvent: MessageEvent) => {
            if (messageEvent.data) {
                try {
                    const data = JSON.parse(messageEvent.data);
                    const { sessionId, host, uid } = data;

                    var isSessionId = sessionId;
                    if (isSessionId) {

                        if (sessionId && host && uid) {
                            setFileSession(new FileSession(sessionId, host, uid));
                        }
                    }
                } catch (e) {

                }
            }
        };

        const handleMessageEvent = (messageEvent: MessageEvent) => {
            handler(messageEvent);
        };

        window.addEventListener("message", handleMessageEvent);

        return () => {
            window.removeEventListener("message", handleMessageEvent);
        };
    }, []);

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <>

            <>
                {fileSession && (!location.pathname.startsWith('/viewer') && !location.search.includes('hideNav=true') )&& <NavigationBar fileSession={fileSession} />}
            </>

            <UnauthenticatedTemplate>
                {
                    (fileSession) ?
                        <Routes>
                            <Route path="/" element={<FileManagerCore fileSession={fileSession} />} />
                            <Route path="/pdf-editor" element={<PdfManagerPage fileSession={fileSession} />} />
                            <Route path="/image-editor" element={<FileManagerImageEditor fileSession={fileSession} />} />
                            <Route path="/word-editor" element={<FileManagerDocumentEditor fileSession={fileSession} />} />
                            <Route path="/spreadsheet-editor" element={<FileManagerSpreadsheetEditor fileSession={fileSession} />} />
                            <Route path="/viewer" element={<FileManagerPreviewer fileSession={fileSession} />} />
                        </Routes> :
                        <div style={outerDivStyle}>
                            <div style={innerDivStyle}>
                                <div>
                                    Failed to connect to the server. Please <a href="#" onClick={reloadPage}>try</a> again.
                                </div>
                            </div>
                        </div>
                }

            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {
                    (fileSession) ?
                        <Routes>
                            <Route path="/" element={<FileManagerCore fileSession={fileSession} />} />
                            <Route path="/pdf-editor" element={<PdfManagerPage fileSession={fileSession} />} />
                            <Route path="/image-editor" element={<FileManagerImageEditor fileSession={fileSession} />} />
                            <Route path="/word-editor" element={<FileManagerDocumentEditor fileSession={fileSession} />} />
                            <Route path="/spreadsheet-editor" element={<FileManagerSpreadsheetEditor fileSession={fileSession} />} />
                            <Route path="/viewer" element={<FileManagerPreviewer fileSession={fileSession} />} />
                        </Routes> :
                        <div style={outerDivStyle}>
                            <div style={innerDivStyle}>
                                <div>
                                    Failed to connect to the server. Please <a href="#" onClick={reloadPage}>try</a> again.
                                </div>
                            </div>
                        </div>
                }
            </AuthenticatedTemplate>
        </>
    );
};
